export default [
  {
    title: 'Matrículas',
    icon: 'ArchiveIcon',
    resource: 'matriculas',
    route: 'matriculas',
    action: 'read',
    // children: [
    //   {
    //     title: 'Crear Matrícula',
    //     resource: 'matriculas',
    //     action: 'create',
    //     route: 'matriculas-create',
    //   },
    //   {
    //     title: 'Lista de Matrículas',
    //     resource: 'matriculas',
    //     action: 'read',
    //     route: 'matriculas',
    //   },
    // ],
  },
]
