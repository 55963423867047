export default [
  {
    header: 'Mantenedores',
    resource: 'mantenedores',
    action: 'read',
  },
  {
    title: 'Periodos',
    route: 'periodos',
    icon: 'ClockIcon',
    resource: 'periodos',
    action: 'read',
  },
  // {
  //   title: 'Calendario Escolar',
  //   route: 'calendarios',
  //   icon: 'CalendarIcon',
  //   resource: 'calendarios',
  //   action: 'read',
  // },

  {
    title: 'Cursos',
    route: 'cursos',
    icon: 'BellIcon',
    resource: 'cursos',
    action: 'read',
  },
  {
    title: 'Plantillas Personalidad',
    route: 'plantillas-personalidad',
    icon: 'EditIcon',
    resource: 'plantillaPerso',
    action: 'read',
  },
  // {
  //   title: 'Horarios',
  //   route: 'horarios',
  //   icon: 'ClockIcon',
  //   resource: 'horarios',
  //   action: 'read',
  // },
  {
    title: 'Docente Reemplazo',
    route: 'reemplazos',
    icon: 'RefreshCwIcon',
    resource: 'reemplazos',
    action: 'read',
  },
  {
    title: 'Usuarios del Sistema',
    route: 'usuarios',
    icon: 'UsersIcon',
    resource: 'usuarios',
    action: 'read',
  },
  {
    title: 'Ajustes Establecimiento',
    route: 'ajustes-establecimiento',
    icon: 'SettingsIcon',
    resource: 'ajustesEstable',
    action: 'read',
  },


  // {
  //   title: 'Forms Elements',
  //   icon: 'CopyIcon',

  //   children: [
  //   ]
  // },
  // {
  //   title: 'Cuentas',
  //   icon: 'UsersIcon',
  //   children: [
  //     {
  //       title: 'Cuentas de Usuarios',
  //       route: 'usuarios',
  //       icon: 'HexagonIcon',
  //       resource: 'usuarios',
  //       action: 'read',
  //     },
  //   ],
  // },
  // {
  //   title: 'Periodos',
  //   route: 'periodos',
  //   icon: 'ClockIcon',
  //   // resource: 'periodos',
  //   // action: 'read',
  // },
  // {
  //   title: 'Servicio Local',
  //   route: 'sostenedores',
  //   icon: 'BookmarkIcon',
  //   // resource: 'sostenedores',
  //   // action: 'read',
  // },

  // {
  //   title: 'Calendario Escolar',
  //   route: 'calendarios',
  //   icon: 'CalendarIcon',
  //   // resource: 'calendarios',
  //   // action: 'read',
  // },
]
