<template>
  <p v-if="!configuracion_inicial" class="clearfix mb-0" style="z-index: 1 !important;">
    <span class="text-center text-md-left d-block d-md-inline-block">
       © {{ new Date().getFullYear() }} |
      Desarrollado por
      <b-link
        class=""
        href="https://softinnova.cl"
        target="_blank"
      >Softinnova</b-link>
      <!-- | -->
      <!-- <span class="d-none d-sm-inline-block">Todos los derechos reservados</span> -->
    </span>

    <!-- <span class="float-md-right d-none d-md-block">Hecho a mano &amp; Hecho con
      <feather-icon
        icon="HeartIcon"
        size="21"
      />
    </span> -->
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      configuracion_inicial: false,
    }
  },
  computed: {
    ...mapGetters({
      getConfiguracionInicial: 'configuracionInicial/getConfiguracionInicial',
    }),
  },
  watch: {
    getConfiguracionInicial(val) {
      this.configuracion_inicial = this.getConfiguracionInicial
    },
  },
  mounted() {
    if (this.getConfiguracionInicial === true) {
      this.configuracion_inicial = this.getConfiguracionInicial
    }
  },
}
</script>
