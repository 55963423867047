export default [

  // SUPER ADMIN
  {
    title: 'Establecimientos',
    route: 'establecimientos',
    icon: 'FlagIcon',
    // GlobeIcon
    resource: 'establecimientos',
    action: 'read',
  },

  // SUPER ADMIN, DIRECTOR
  {
    title: 'Libros',
    icon: 'BookIcon',
    route: 'libros',
    resource: 'libros',
    action: 'read',
  },

  // DOCENTE, EDUCADOR DIFERENCIAL, PROFESIONAL
  {
    title: 'Ver Libros',
    icon: 'BookOpenIcon',
    route: 'ver-libros',
    resource: 'abrirLibros',
    action: 'read',
  },
  {
    title: 'Informes',
    icon: 'FileTextIcon',
    resource: 'informes',
    action: 'read',
    route: 'informes',
  },
  {
    title: 'Salidas',
    icon: 'ArrowRightIcon',
    resource: 'salidas',
    action: 'read',
    route: 'salidas',
  },
  {
    title: 'Comunicaciones',
    icon: 'SmartphoneIcon',
    resource: 'comunicaciones',
    action: 'read',
    route: 'comunicaciones-apoderados',
  },
  {
    title: 'Reuniones',
    icon: 'UsersIcon',
    // ClockIcon
    route: 'reuniones-apoderados',
    resource: 'reunionesApoderados',
    action: 'read',
  },
  {
    title: 'Mis Horarios',
    icon: 'CalendarIcon',
    route: 'mis-horarios',
    resource: 'misHorarios',
    action: 'read',
  },


]
