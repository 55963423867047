export default [
  // {
  //   header: 'Apps & Pages',
  // },
  {
    title: 'Configuraciones',
    icon: 'ToolIcon',
    children: [
      {
        title: 'Configurar Cursos y Asignaturas',
        route: 'cursos',
        icon: 'ToolIcon',
        // resource: 'configurarCursos',
        // action: 'read',
      },
      {
        title: 'Configurar Promedios',
        route: 'promedios',
        icon: 'ToolIcon',
        // resource: 'configurarPromedios',
        // action: 'read',
      },
    ],
  },
]
